import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "views/Pages/useAuth";

export default function NewUserModal(props) {
  const { isOpen, onClose } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [role, setRole] = useState("");
  const [tempPassword, setTempPassword] = useState();

  //const { company } = useAuth();

  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const createUser = async function () {
    if (!role) {
      showToast({
        status: "warning",
        title: "Warning",
        description: "Please assign a role to the user",
      });
      return;
    }
    console.log({
      fullName: name,
      email: email,
      company: { id: companyId },
      role: role,
    })
    await axios
      .put("/users", {
        fullName: name,
        email: email,
        company: { id: companyId },
        role: role,
      })
      .then((response) => {
        onClose();
        showToast({
          status: "success",
          title: "User created successfully",
          description: "User has been created successfully",
        });
        return response.data;
      })
      .catch((e) => {
        showToast({
          status: "error",

          description: "Please try again later",
        });
      });
  };

  useEffect(() => {
    //fetchProducts();
  }, []);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <FormControl>
            <FormLabel>Name </FormLabel>
            <Input
              ref={initialRef}
              placeholder="account holder"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel>Email </FormLabel>
            <Input
              placeholder="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel>Company ID </FormLabel>
            <Input
              placeholder="A00..."
              type="text"
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel>User's Account Role </FormLabel>
            <Select
              placeholder="Account role"
              onChange={(e) => setRole(e.target.value)}
            >
              <option value={"admin"}>admin</option>
              <option value={"customer"}>customer</option>
              <option value={"support"}>support</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} color="red">
            Cancel
          </Button>
          <Button colorScheme="blue" mr={3} ml={3} onClick={() => createUser()}>
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
